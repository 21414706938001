import './App.css';
import { useState, useEffect } from 'react';
import detectEthereumProvider from '@metamask/detect-provider'
import { ethers } from 'ethers'
import members from './Members.json'
import factory from './Factory.json'
import wrappedToken from './WrappedToken.json'


import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const CONTRACTS = {
    "WTICO" : {
	"BSC": {
	    "tokenContract": "0x9ed4b2D6bC2F193b22D62681223d5EF731F6EB4a",
	    "factoryContractAddress" : "0x08B4d506B1b67Ce7F269d104f64d57D3710ea3aa",
	    "membersContract" : "0xE040fD7E371F28692e14c93F3a89CEaEf681deFa"
	}
    },
    "WBEAM": {
	"BSC": {
	    "tokenContract": "0xC98CE9084Cb9ccf3188011B6FFBbA91FBC470C96",
	    "factoryContractAddress" : "0x9939662eF49310F3A5FE488812C4bEeEB071e94A",
	    "membersContract" : "0x7112A786c32349e4207dA18e40e5dA123B3Fca92"
	}
    }
};

function OrderBook(props) {
    const {web3, factoryContract} = props;

    const [state, setState] = useState({
	requests: [],
	partial: false,
	isLoading: false,
	loaded: ""
    })

    function bumpState(newStateChunk) {
        setState(prev => {return {
            ...prev,
            ...newStateChunk
        }});
    }

    async function contractRead(contractAddress, contractAbi, doer, notifier) {
        const protocol = new ethers.Contract(contractAddress, contractAbi, web3)
        try{
            bumpState(notifier(true));
            await doer(protocol);
        } catch (e) {
            //ignore
            if (e.code !== 4001)
                console.log(e);
        } finally {
            bumpState(notifier(false));
        }
    }

    if (!state.isLoading && state.loaded !== factoryContract) {
	state.isLoading = true;

	contractRead(factoryContract, factory.abi,
		     async (c) => {
			 const mintLen = await c.getMintRequestsLength();

			 const MAX_LOAD = 5;

			 let partial = mintLen > MAX_LOAD

			 let requests = [];
			 for(var i = Math.max(0, mintLen-MAX_LOAD); i < mintLen; i++) {
			     const request = await c.getMintRequest(i);

			     const date = new Date(request.timestamp.toNumber() * 1000).toLocaleString();
			     requests.push({...request, ...{date: date, wrap: true, amount: request.amount.div(ethers.BigNumber.from(10).pow(8))}});
			 }

			 const burnLen = await c.getBurnRequestsLength()

			 partial = partial || burnLen > MAX_LOAD

			 for(i = Math.max(0, burnLen-MAX_LOAD); i < burnLen; i++) {
			     const burn = await c.getBurnRequest(i);

			     const date = new Date(burn.timestamp.toNumber() * 1000).toLocaleString();
			     requests.push({...burn, ...{date: date, wrap: false, amount: burn.amount.div(ethers.BigNumber.from(10).pow(8))}});
			 }

			 requests.sort((a,b) => { if(a.timestamp.gt(b.timestamp)) return -1; else if (a.timestamp.lt(b.timestamp)) return 1; else return 0;});

			 bumpState({requests, partial});
		     },
		     (b) => ({isLoading: b}))
	    .then(() => bumpState({loaded: factoryContract}));

    }
    return (
    <div>
      {(state.isLoading) &&
      <div className="spinner-border" role="status">
	<span className="visually-hidden">Please wait...</span>
      </div>
      }

      <table className="table">
	<thead>
	  <tr>
	    <th scope="col">Date</th>
	    <th scope="col">Provider</th>
	    <th scope="col">Amount</th>
	    <th scope="col">What</th>
	    <th scope="col">Status</th>
	  </tr>
	</thead>
	<tbody>
	  {
	  state.requests.map(r =>

	  <tr key={r.timestamp.toString()}>
	    <th scope="row">{r.date}</th>
	    <td>{r.requester}</td>
	    <td>{r.amount.toString()}</td>
	    <td>{r.wrap ?  <span>&#x1F381;</span> : <span>&#x1F525;</span> }</td>
	    <td>{r.status}</td>
	  </tr>
	  )
	  }

	  { state.partial &&
	  <tr key="partial">
	    <th scope="row">...</th>
	    <td> <div className="text-secondary">Only latest transactions shown.</div></td>
	    <td></td>
	    <td></td>
	    <td></td>
	  </tr>
	  }
	</tbody>
      </table>
    </div>
    );
}

function MakeRequests(props) {
    const {web3, factoryContract, tokenContract} = props;
    const [state, setState] = useState({
	merchantAddress: "",
	mintAmount : "",
	mintTx: "",
	mintAddress: "",
	burnAmount: "",
        isSettingAddress: false,
	isMinting: false,
	isApproving: false,
	isBurning: false
    })

    function bumpState(newStateChunk) {
        setState(prev => {return {
            ...prev,
            ...newStateChunk
        }});
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        bumpState({[name]: value, valid: true})
    }

    async function contractWrite(contractAddress, contractAbi, doer, notifier) {
        const signer = web3.getSigner()
        const protocol = new ethers.Contract(contractAddress, contractAbi, signer)
        try{
            bumpState(notifier(true));
            const tx = await doer(protocol);
            await tx.wait();
        } catch (e) {
            //ignore
            if (e.code !== 4001)
                console.log(e);
        } finally {
            bumpState(notifier(false));
        }
    }

    async function factoryWrite(doer, notifier) {
        return await contractWrite(factoryContract, factory.abi, doer, notifier);
    }
    

    async function setMerchantDepositAddress(event) {
        event.preventDefault();

	await factoryWrite(
            (contract) => contract.setMerchantDepositAddress(state.merchantAddress, {gasLimit: 250000}),
            (b) => ({isSettingAddress: b}));
    }

    async function addMintRequest(event) {
        event.preventDefault();

	if (state.mintAmount.includes(".")) {
	    console.log("Fixed numbers please");
	    return;
	}
	
	const mintAmount = ethers.BigNumber.from(state.mintAmount)
	      .mul(ethers.BigNumber.from(10).pow(8));

	await factoryWrite(
            (contract) => contract.addMintRequest(mintAmount, state.mintTx, state.mintAddress, {gasLimit: 450000}),
            (b) => ({isMinting: b}));
    }

    async function burn(event) {
        event.preventDefault();

	if (state.burnAmount.includes(".")) {
	    console.log("Fixed numbers please");
	    return;
	}

	const burnAmount = ethers.BigNumber.from(state.burnAmount)
	      .mul(ethers.BigNumber.from(10).pow(8));

	await factoryWrite(
            (contract) => contract.burn(burnAmount, {gasLimit: 450000}),
            (b) => ({isBurning: b}));
    }

    async function approveForBurn(event) {
        event.preventDefault();

	if (state.burnAmount.includes(".")) {
	    console.log("Fixed numbers please");
	    return;
	}

	const burnAmount = ethers.BigNumber.from(state.burnAmount)
	      .mul(ethers.BigNumber.from(10).pow(8));

	await contractWrite(tokenContract, wrappedToken.abi,
            (contract) => contract.approve(factoryContract, burnAmount),
            (b) => ({isApproving: b}));
    }

    return (

<form className="row g-3">
  <div className="col-md-12">
    <label htmlFor="mintAmount" className="form-label">Mint amount</label>
    <input type="text" className="form-control col-sm-10" id="mintAmount" name="mintAmount" value={state.mintAmount} onChange={handleChange}/>
  </div>
  <div className="col-md-12">
    <label htmlFor="mintTx" className="form-label">Your transaction</label>
    <input type="text" className="form-control col-sm-10" id="mintTx" name="mintTx" value={state.mintTx} onChange={handleChange}/>
  </div>
  <div className="col-md-12">
    <label htmlFor="mintAddress" className="form-label">To address</label>
    <input type="text" className="form-control col-sm-10" id="mintAddress" name="mintAddress" value={state.mintAddress} onChange={handleChange}/>
  </div>
  <div className="col-10">
    <button className="btn btn-primary" onClick={addMintRequest}>Add mint request</button>
  </div>
  <div className="col-2">
    {(state.isMinting) &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>

  <div className="col-md-12">
    <label htmlFor="burnAmount" className="form-label">Burn amount</label>
    <input type="text" className="form-control col-sm-10" id="burnAmount" name="burnAmount" value={state.burnAmount} onChange={handleChange}/>
  </div>
  <div className="col-5">
    <button className="btn btn-primary" onClick={approveForBurn}>Approve</button>
  </div>
  <div className="col-5">
    <button className="btn btn-primary" onClick={burn}>Add burn request</button>
  </div>
  <div className="col-2">
    {(state.isBurning || state.isApproving) &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>
  <div className="col-md-12">
    <label htmlFor="merchantAddress" className="form-label">Deposit address</label>
    <input type="text" className="form-control" id="merchantAddress" name="merchantAddress" value={state.merchantAddress} onChange={handleChange}/>
  </div>
  <div className="col-2">
    <button type="submit" className="btn btn-primary" onClick={setMerchantDepositAddress}>Set</button>
  </div>
  <div className="col-2">
    {state.isSettingAddress &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>

</form>

    );
}


function ConfirmRequests(props) {
    const {web3, factoryContract} = props;
    const [state, setState] = useState({
	hash: "",
	burnHash: "",
        isAdding: false,
    })

    function bumpState(newStateChunk) {
        setState(prev => {return {
            ...prev,
            ...newStateChunk
        }});
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        bumpState({[name]: value, valid: true})
    }

    async function contractWrite(contractAddress, contractAbi, doer, notifier) {
        const signer = web3.getSigner()
        const protocol = new ethers.Contract(contractAddress, contractAbi, signer)
        try{
            bumpState(notifier(true));
            const tx = await doer(protocol);
            await tx.wait();
        } catch (e) {
            //ignore
            if (e.code !== 4001)
                console.log(e);
        } finally {
            bumpState(notifier(false));
        }
    }

    async function factoryWrite(doer, notifier) {
        return await contractWrite(factoryContract, factory.abi, doer, notifier);
    }
    
    async function confirmMintRequest(event) {
        event.preventDefault();

	await factoryWrite(
            (contract) => contract.confirmMintRequest(state.hash, {gasLimit: 250000}),
            (b) => ({isAdding: b}));
    }

    async function confirmBurnRequest(event) {
        event.preventDefault();

	await factoryWrite(
            (contract) => contract.confirmBurnRequest(state.burnHash, {gasLimit: 250000}),
            (b) => ({isRemoving: b}));
    }

    return (

<form className="row g-3">
  <div className="col-md-12">
    <label htmlFor="hash" className="form-label">Request hash</label>
    <input type="text" className="form-control" id="hash" name="hash" value={state.hash} onChange={handleChange}/>
  </div>
  <div className="col-2">
    <button type="submit" className="btn btn-primary" onClick={confirmMintRequest}>Confirm</button>
  </div>
  <div className="col-2">
    {state.isAdding &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>
  <div className="col-md-12">
    <label htmlFor="burnHash" className="form-label">Burn request hash</label>
    <input type="text" className="form-control col-sm-10" id="burnHash" name="burnHash" value={state.burnHash} onChange={handleChange}/>
  </div>
  <div className="col-10">
    <button className="btn btn-primary" onClick={confirmBurnRequest}>Confirm burn</button>
  </div>
  <div className="col-2">
    {(state.isRemoving) &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>
</form>

    );
}


function AddRemoveMembers(props) {
    const {web3, membersContract, factoryContract} = props;
    const [state, setState] = useState({
	address: "",
	isAdding: false,
	removeAddress: "",
	isRemoving: false,
	merchantAddress: "",
	custodianDepositAddress: "",
	isSettingAddress: false,
    })

    function bumpState(newStateChunk) {
        setState(prev => {return {
            ...prev,
            ...newStateChunk
        }});
    }

    function handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        bumpState({[name]: value, valid: true})
    }

    async function contractWrite(contractAddress, contractAbi, doer, notifier) {
        const signer = web3.getSigner()
        const protocol = new ethers.Contract(contractAddress, contractAbi, signer)
        try{
            bumpState(notifier(true));
            const tx = await doer(protocol);
            await tx.wait();
        } catch (e) {
            //ignore
            if (e.code !== 4001)
                console.log(e);
        } finally {
            bumpState(notifier(false));
        }
    }

    async function membersWrite(doer, notifier) {
        return await contractWrite(membersContract, members.abi, doer, notifier);
    }
    
    async function factoryWrite(doer, notifier) {
        return await contractWrite(factoryContract, factory.abi, doer, notifier);
    }

    async function addMember(event) {
        event.preventDefault();

	await membersWrite(
            (contract) => contract.addMerchant(state.address, {gasLimit: 250000}),
            (b) => ({isAdding: b}));
    }

    async function removeMember(event) {
        event.preventDefault();

	await membersWrite(
            (contract) => contract.removeMerchant(state.removeAddress, {gasLimit: 250000}),
            (b) => ({isRemoving: b}));
    }

    async function setCustodianDepositAddress(event) {
        event.preventDefault();

	await factoryWrite(
            (contract) => contract.setCustodianDepositAddress(state.merchantAddress, state.custodianDepositAddress),
            (b) => ({isSettingAddress: b}));
    }

    return (

<form className="row g-3">
  <div className="col-md-12">
    <label htmlFor="address" className="form-label">Add address</label>
    <input type="text" className="form-control" id="address" name="address" value={state.address} onChange={handleChange}/>
  </div>
  <div className="col-2">
    <button type="submit" className="btn btn-primary" onClick={addMember}>Add member</button>
  </div>
  <div className="col-2">
    {state.isAdding &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
	</div>


  <div className="col-md-12">
    <label htmlFor="merchantAddress" className="form-label">Member</label>
    <input type="text" className="form-control" id="merchantAddress" name="merchantAddress" value={state.merchantAddress} onChange={handleChange}/>
  </div>
  <div className="col-md-12">
    <label htmlFor="custodianDepositAddress" className="form-label">Deposit address</label>
    <input type="text" className="form-control" id="custodianDepositAddress" name="custodianDepositAddress" value={state.custodianDepositAddress} onChange={handleChange}/>
  </div>
  <div className="col-2">
    <button type="submit" className="btn btn-primary" onClick={setCustodianDepositAddress}>Set</button>
  </div>
  <div className="col-2">
    {state.isSettingAddress &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>

  <div className="col-md-12">
    <label htmlFor="removeAddress" className="form-label">Remove address</label>
    <input type="text" className="form-control col-sm-10" id="removeAddress" name="removeAddress" value={state.removeAddress} onChange={handleChange}/>
  </div>
  <div className="col-10">
    <button className="btn btn-primary" onClick={removeMember}>Remove member</button>
  </div>
  <div className="col-2">
    {(state.isRemoving) &&
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Please wait...</span>
    </div>
    }
  </div>
</form>

    );
}

function App() {
    const [ethereum, setEthereum] = useState(null)
    const [web3, setWeb3] = useState(null)
    const [accounts, setAccounts] = useState(null)
    const [chain, setChain] = useState(null);
    const [token, setToken] = useState("WTICO");

    const tokenHeaderTitle = {
	"WTICO": "Wrapped Tico",
	"WBEAM": "Wrapped Beam"
    }

    function shortAddress(a) {
        if (!a)
            return a;
        return a.substring(0, 6) + "..." + a.substring(a.length - 4, a.length);
    }

    if (ethereum == null) {
	//        console.log("No ethereum");
        detectEthereumProvider().then(eth => {
            if (!eth)
                return;

            setEthereum(eth);
            if (web3 == null) {
                setWeb3(new ethers.providers.Web3Provider(eth));
            }
        });
    }

    function toChain(chainId) {
        //https://chainlist.org/
        switch (chainId) {
        case 1:
            return "ETH";
        case 56:
            return "BSC";
        case 97:
            return "BSC-Test";
        case 137:
            return "Polygon";
        case 80001:
            return "Polygon-Test";
        case 42161:
            return "Arbitrum";
        case 1337:
            return "localhost";
        default:
            return "?";
        }
    }

    useEffect(() => {
        if (ethereum) {
            ethereum.on('accountsChanged', setAccounts);
            ethereum.on('chainChanged', window.location.reload);
            ethereum.request({ method: 'eth_accounts' })
                .then(setAccounts);
            ethereum.request({ method: 'eth_chainId' })
	    //                .then(console.log);
                .then(id => setChain(toChain(parseInt(id, 16))));
        }
        return function cleanup() {
            if (ethereum) {
                ethereum.removeListener('accountsChanged', setAccounts);
                ethereum.removeListener('chainChanged', window.location.reload);
            }
        };
    }, [ethereum]);

    async function requestAccount() {
        ethereum.request({ method: 'eth_requestAccounts' })
            .then(setAccounts);
    }

    let factoryContractAddress;
    let membersContractAddress;
    let tokenContractAddress;

    let allChainsTokenInfo = CONTRACTS[token];
    if (chain) {
	let currentTokenInfo = allChainsTokenInfo[chain];
	if (currentTokenInfo) {
	    factoryContractAddress = currentTokenInfo.factoryContractAddress;
	    membersContractAddress = currentTokenInfo.membersContract;
	    tokenContractAddress = currentTokenInfo.tokenContract;
	}
    }

    function onTokenChange(eventKey, event) {
	setToken(eventKey);
    }

    return (
<div className="App">
  <header className="App-header">
    <div className="container">
      <div className="row">
	<div className="col s12 m6 l6">
	    { tokenHeaderTitle[token] }
     	</div>
  	<div className="col s12 m6 l6">

	  {(chain && !factoryContractAddress) && <b>Wrong network! Use BSC.</b>}

	  {ethereum ?
          ((accounts == null || accounts.length === 0) ?
          <Button variant="primary" onClick={requestAccount}>Connect</Button>
	  :
	  <Button variant="primary">{chain + " | " + shortAddress(accounts[0])}</Button>
	  ) :
	  <Button variant="primary">Install Metamask</Button>
	  }
	</div>
	<div className="col s12 m6 l6">
	  <DropdownButton id="dropdown-basic-button" title={token} onSelect={onTokenChange}>
	    <Dropdown.Item eventKey="WTICO">WTICO on BSC</Dropdown.Item>
	    <Dropdown.Item eventKey="WBEAM">WBEAM on BSC</Dropdown.Item>
	  </DropdownButton>
	</div>
      </div>

    </div>
    
  </header>

  <Tabs
    defaultActiveKey="home"
    id="maintab"
    className="mb-3"
    >
    <Tab eventKey="home" title="Home">
      <p>Wrapped TICO delivers the caw caaw &#x1F99C; of TICO with the flexibility
	of a BSC token.</p>

      <p>Wrapped TICO (WTICO) BSC address: 0x9ed4b2D6bC2F193b22D62681223d5EF731F6EB4a</p>

      <img src="parrot.jpg" alt="Parrot"/>

    </Tab>
    {(web3 && factoryContractAddress && membersContractAddress) &&
    <Tab eventKey="admin" title="Administrative">

      <h2>Wrapping &#x1F381;</h2>

      <MakeRequests web3={web3} factoryContract={factoryContractAddress} tokenContract={tokenContractAddress} />

      <h2>Administrative &#x1F4D2;</h2>

      <AddRemoveMembers web3={web3} membersContract={membersContractAddress} factoryContract={factoryContractAddress} />

      <ConfirmRequests web3={web3} factoryContract={factoryContractAddress} />

      
    </Tab>
    }
    <Tab eventKey="orderbook" title="Order book">

      {(web3 && factoryContractAddress) &&
      <OrderBook web3={web3} factoryContract={factoryContractAddress} /> }
      {!web3 &&
      <div>Connect wallet to see on-chain order book.</div>
      }
    </Tab>

  </Tabs>
  <hr/>
  {chain}
</div>
    );
}

export default App;
